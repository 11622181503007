<template>
  <!-- 园区导览 -->
  <div class="tabDetailWrap en">
    <div>
      <div class="tabDetail">
        <div class="titleWrap">
          <img class="tabLogo" :src="require('../../assets/image/guide_en/title.png')" alt />
          <!-- <div class="logoRight">
            <span class="title">园区导览</span>
            <div class="subtitle">
              <img :src="require('../../assets/image/guide/subtitle.png')" alt />
            </div>
          </div>-->
        </div>
        <!-- 地图部分 -->
        <div class="mapGroupWrap">
          <img :src="require('../../assets/image/guide/bottom_dec.png')" alt />
          <div class="mapWrap">
            <iframe
              id="map"
              width="100%"
              height="100%"
              src="https://tdll.worldmaipu.com/web/index.html#/index"
            ></iframe>
          </div>
          <a
            class="btn ilarge trans"
            href="https://tdll.worldmaipu.com/web/index.html#/index"
            target="_blank"
          >
            Enlarge
            <img :src="require('../../assets/image/guide/large.png')" alt />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.tabDetailWrap {
  min-height: 500px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  margin-bottom: 240px;
  .tabDetail {
    .titleWrap {
      .tabLogo {
        vertical-align: super;
        margin-right: 47px;
        // width: 104px;
        // height: 100px;
      }
      .logoRight {
        display: inline-block;
        .title {
          font-size: 45px;
          font-weight: bold;
          color: $main-purple;
          margin-bottom: 20px;
        }
        .subtitle {
          margin-top: 20px;
        }
      }
    }
    .mapGroupWrap {
      position: relative;
      width: 979px;
      height: 658px;
      margin-top: 53px;
      background: $main-white;
      > .mapWrap {
        position: absolute;
        width: 979px;
        height: 658px;
        border: 10px solid $main-blue;
        border-radius: 10px;
        top: 0;
        left: 0;
      }
      > img {
        position: absolute;
        bottom: -174px;
        left: -23px;
        // z-index:-1;
      }
      .btn {
        position: absolute;
        display: block;
        width: 142px;
        height: 42px;
        line-height: 42px;
        top: -51px;
        right: 6px;
        background: $main-blue;
        text-align: center;
        border-radius: 10px;
        color: $main-white;
        font-size: 16px;
        &:hover {
          background: $main-blueDark;
        }
        > img {
          vertical-align: middle;
          margin-left: 15px;
          transform: scale(1.2);
        }
      }
      a,
      a:focus,
      a:hover,
      a.active {
        color: $main-white;
      }
    }
  }
}
</style>
